import React from 'react';
import Footer from "../components/Footer";
import Button from "../components/Button";
import EmbeddedMap from "../components/Map";
import Forms from "../components/Forms";

const Contato = ( { click, colors, contactInfo } ) => {
    const instaButton = '/resources/imgs/contato/ig-icon.svg';
    const mailButton = '/resources/imgs/contato/mail-icon.svg';
    const wppButton = '/resources/imgs/contato/wpp-icon.svg';

    return (
        <div>
            <div className="flex flex-col min-h-screen items-center">
                <div id='flex-container' className={`items-center flex flex-row flex-wrap justify-center pt-32 sm:pt-40 lg:pt-48 xl:pt-52 text-4xl sm:text-6xl md:text-7xl xl:text-8xl w-full pb-10`}>
                    <div className={`font-sans ${colors["text"]} px-1`}>
                        FAÇA UM <span className='font-bold'>ORÇAMENTO</span>
                    </div>
                </div>
                <Forms/>
                <div className="flex flex-row w-4/5 md:w-11/12 xl:w-4/5 2xl:w-2/3 py-5 lg:pt-20 mx-auto justify-center">
                    <img src="/resources/imgs/contato/boxes_large.png" alt="servicos" className={"hidden lg:block"}/>
                    <img src="/resources/imgs/contato/boxes_medium.png" alt="servicos" className={"hidden md:block lg:hidden"}/>
                    <img src="/resources/imgs/contato/boxes_small.png" alt="servicos" className={"block md:hidden"}/>
                </div>
                <div className="flex flex-col md:flex-row md:pb-5 md pt-10 xl:pt-20 text-4xl sm:text-5xl lg:text-6xl">
                    <div className={`font-sans ${colors["text"]} px-2 lg:px-3 mx-auto`}> ENTRE EM </div>
                    <div className={`font-sans font-bold ${colors["text"]} mx-auto`}> CONTATO CONOSCO </div>
                </div>
                <hr className={`hidden md:block w-2/5 lg:w-1/3 xl:w-3/12 border-1 ${colors["border"]}`}/>
                <div className="flex flex-row items-center py-10 md:py-5 w-3/5 md:w-1/2 lg:w-1/3 xl:w-3/12 mx-auto">
                    <Button link={contactInfo["wpp"]} img_path={wppButton}/>
                    <Button link={contactInfo["mail"]} img_path={mailButton}/>
                    <Button link={contactInfo["ig"]} img_path={instaButton}/>
                </div>
                <hr className={`hidden md:block w-2/5 lg:w-1/3 xl:w-3/12 border-1 ${colors["border"]}`}/>
                <div className="flex flex-col md:flex-row items-center pb-10 md:pt-5 text-xl md:text-xl lg:text-2xl xl:text-3xl w-4/5 2xl:w-3/5">
                    <div className={`font-sans ${colors["text"]} mx-auto`}> Telefone: (31) 99166 8272 </div>
                    <div className={`hidden md:block font-sans ${colors["text"]} mx-auto`}> | </div>
                    <a href={contactInfo["mail"]} className={`font-sans ${colors["text"]} mx-auto hover:underline`}> jraposo.arq@gmail.com </a>
                    <div className={`hidden md:block font-sans ${colors["text"]} mx-auto`}> | </div>
                    <a href={contactInfo["ig"]} className={`font-sans ${colors["text"]} mx-auto hover:underline`}> @jraposoarquiteta </a>
                </div>
                <div className="flex w-full flex-col md:flex-row pb-10 pt-20 justify-center">
                    <div className={`font-sans text-4xl sm:text-5xl lg:text-6xl ${colors["text"]} mx-auto md:mx-1`}> ATENDIMENTO </div>
                    <div className={`font-sans font-bold text-4xl sm:text-5xl lg:text-6xl ${colors["text"]} mx-auto md:mx-1`}> PRESENCIAL </div>
                </div>
                <div className="flex flex-row items-center py-2 w-11/12 sm:w-4/5 lg:w-3/5 xl:w-3/5">
                    <div className={`font-sans text-xl md:text-2xl ${colors["text"]} ml-auto`}> Belo Horizonte </div>
                    <div className={`font-sans text-xl md:text-2xl ${colors["text"]} mx-auto`}> | </div>
                    <div className={`font-sans text-xl md:text-2xl ${colors["text"]} mx-auto`}> Muriaé </div>
                    <div className={`font-sans text-xl md:text-2xl ${colors["text"]} mx-auto`}> | </div>
                    <div className={`font-sans text-xl md:text-2xl ${colors["text"]} mx-auto`}> Ponte Nova </div>
                    <div className={`font-sans text-xl md:text-2xl ${colors["text"]} mx-auto`}> | </div>
                    <div className={`font-sans text-xl md:text-2xl ${colors["text"]} mr-auto`}> Viçosa </div>
                </div>
                <div className={"flex flex-row w-full items-center"}>
                    <EmbeddedMap/>
                </div>
            </div>
            <Footer colors={colors}/>
        </div>
    );
};

export default Contato;