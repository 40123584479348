import React, {useState} from 'react';

export const DepoimentCarrouselLarge = ({ colors, depoimentos, dadosProjetos }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === Object.keys(depoimentos).filter((key) => depoimentos[key] !== "" && key !== "aptoGabiJoao" && key !== "escritorioMemories").length - 3 ? 0 : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? Object.keys(depoimentos).filter((key) => depoimentos[key] !== "" && key !== "aptoGabiJoao" && key !== "escritorioMemories").length - 3 : prevIndex - 1
        );
    };

    return (
        <div className="relative w-11/12 overflow-hidden mx-auto">
            <div className="flex items-center justify-between">
                {/* Previous Button */}
                <button
                    onClick={prevSlide}
                    className="left-0 text-white rounded-full scale-75 drop-shadow-lg"
                >
                    <img src="/resources/imgs/home/leftArrow.png" alt=""/>
                </button>

                {/* Cards Container */}
                <div className="w-full overflow-hidden">
                    <div
                        className="flex transition-transform duration-500"
                        style={{
                            transform: `translateX(-${currentIndex * 100 / 3}%)`,
                        }}
                    >
                        {Object.keys(depoimentos).filter((key) => depoimentos[key] !== "" && key !== "aptoGabiJoao" && key !== "escritorioMemories").map((key) => (
                            <div
                                key={key}
                                className="w-1/3 flex-shrink-0 px-4 py-6"
                            >
                                <div className={"min-w-[33.333%] h-full flex-shrink-0 px-4 py-6 bg-[#F8F6FF] rounded-semifull drop-shadow-lg"}>
                                    <div className={`flex flex-row font-sans text-3xl justify-center pt-4 ${colors["text"]}`}>
                                        {dadosProjetos[key]["depoimentoName"]}
                                    </div>
                                    <div className={`flex flex-row h-full pb-20 items-center font-sans text-xl justify-center px-7 text-center ${colors["text"]} leading-5`}>
                                        {depoimentos[key]}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Next Button */}
                <button
                    onClick={nextSlide}
                    className="right-0 text-white rounded-full scale-75 drop-shadow-lg"
                >
                    <img src="/resources/imgs/home/rightArrow.png" alt=""/>
                </button>
            </div>
        </div>
    );
};

export const DepoimentCarrouselMedium = ({ colors, depoimentos, dadosProjetos }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === Object.keys(depoimentos).filter((key) => depoimentos[key] !== "" && key !== "aptoGabiJoao" && key !== "escritorioMemories").length - 2 ? 0 : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? Object.keys(depoimentos).filter((key) => depoimentos[key] !== "" && key !== "aptoGabiJoao" && key !== "escritorioMemories").length - 2 : prevIndex - 1
        );
    };

    return (
        <div className="relative w-full overflow-hidden mx-auto">
            <div className="flex items-center justify-between">
                {/* Previous Button */}
                <button
                    onClick={prevSlide}
                    className="left-0 text-white rounded-full scale-75 drop-shadow-lg"
                >
                    <img src="/resources/imgs/home/leftArrow.png" alt=""/>
                </button>

                {/* Cards Container */}
                <div className="w-full l overflow-hidden">
                    <div
                        className="flex transition-transform duration-500"
                        style={{
                            transform: `translateX(-${currentIndex * 100 / 2}%)`,
                        }}
                    >
                        {Object.keys(depoimentos).filter((key) => depoimentos[key] !== "" && key !== "aptoGabiJoao" && key !== "escritorioMemories").map((key) => (
                            <div
                                key={key}
                                className="w-1/2 flex-shrink-0 px-4 py-6"
                            >
                                <div className={"min-w-[50%] h-full flex-shrink-0 px-4 py-6 bg-[#F8F6FF] rounded-semifull drop-shadow-lg"}>
                                    <div className={`flex flex-row font-sans text-3xl justify-center pt-4 ${colors["text"]}`}>
                                        {dadosProjetos[key]["depoimentoName"]}
                                    </div>
                                    <div className={`flex flex-row h-full pb-20 items-center font-sans text-xl justify-center px-7 text-center ${colors["text"]} leading-5`}>
                                        {depoimentos[key]}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Next Button */}
                <button
                    onClick={nextSlide}
                    className="right-0 text-white rounded-full scale-75 drop-shadow-lg"
                >
                    <img src="/resources/imgs/home/rightArrow.png" alt=""/>
                </button>
            </div>
        </div>
    );
};

export const DepoimentCarrouselSmall = ({ colors, depoimentos, dadosProjetos }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === Object.keys(depoimentos).filter((key) => depoimentos[key] !== "" && key !== "aptoGabiJoao" && key !== "escritorioMemories").length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? Object.keys(depoimentos).filter((key) => depoimentos[key] !== "" && key !== "aptoGabiJoao" && key !== "escritorioMemories").length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="relative overflow-hidden">
            <div className="flex flex-row items-center justify-between">
                {/* Previous Button */}
                <button
                    onClick={prevSlide}
                    className="left-0 text-white rounded-full scale-50 drop-shadow-lg"
                >
                    <img src="/resources/imgs/home/leftArrow.png" alt=""/>
                </button>

                {/* Cards Container */}
                <div className="w-full overflow-hidden h-full items-center">
                    <div
                        className="flex transition-transform duration-500"
                        style={{
                            transform: `translateX(-${currentIndex * 100}%)`,
                        }}
                    >
                        {Object.keys(depoimentos).filter((key) => depoimentos[key] !== "" && key !== "aptoGabiJoao" && key !== "escritorioMemories").map((key) => (
                            <div
                                key={key}
                                className="w-full px-4 flex-shrink-0 py-6"
                            >
                                <div className={"min-w-[100%] h-full flex-shrink-0 px-4 py-6 bg-[#F8F6FF] rounded-semifull drop-shadow-lg"}>
                                    <div className={`flex flex-row font-sans text-xl justify-center pt-4 ${colors["text"]}`}>
                                        {dadosProjetos[key]["depoimentoName"]}
                                    </div>
                                    <div className={`flex flex-row h-full pb-10 items-center font-sans text-base justify-center px-5 text-center ${colors["text"]} leading-5`}>
                                        {depoimentos[key]}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Next Button */}
                <button
                    onClick={nextSlide}
                    className="right-0 text-white rounded-full scale-50 drop-shadow-lg"
                >
                    <img src="/resources/imgs/home/rightArrow.png" alt=""/>
                </button>
            </div>
        </div>
    );
};