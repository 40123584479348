import React from 'react';
import {Route, Routes} from "react-router-dom";
import Home from "../pages/home";
import Portfolio from "../pages/portfolio";
import Servicos from "../pages/servicos";
import Sobre from "../pages/sobre";
import Contato from "../pages/contato";
import ProjetoTwoImagesDepo from "../pages/projetos/ProjetoTwoImagesDepo";
import dadosProjetos from "../dadosProjetos.json";
import depoimentos from "../depoimentos.json";
import ProjetoOneImgNoDepo from "../pages/projetos/ProjetoOneImgNoDepo";
import AptoNathalia from "../pages/projetos/AptoNathalia";
import ProjetoOneImageDepo from "../pages/projetos/ProjetoOneImageDepo";

const Router = ( {setPage, colorVariants, page, contactInfo, projetos} ) => {
    return (
        <Routes>
            <Route path="/" element={<Home click={setPage} colors={colorVariants[page]} dadosProjetos={dadosProjetos} depoimentos={depoimentos}/>} />
            <Route path="/portfolio" element={<Portfolio click={setPage} colors={colorVariants[page]} projetos={projetos}/>} />
            <Route path="/servicos" element={<Servicos click={setPage} colors={colorVariants[page]}/>} />
            <Route path="/sobre" element={<Sobre click={setPage} colors={colorVariants[page]}/>} />
            <Route path="/contato" element={<Contato click={setPage} colors={colorVariants[page]} contactInfo={contactInfo}/>} />
            <Route path={"/portfolio/escritorioMemories"} element={<ProjetoTwoImagesDepo click={setPage} colors={colorVariants[page]} info={dadosProjetos["escritorioMemories"]} depoimento={depoimentos["escritorioMemories"]} name={"escritorioMemories"}/>}/>
            <Route path={"/portfolio/aptoGabiJoao"} element={<ProjetoTwoImagesDepo click={setPage} colors={colorVariants[page]} info={dadosProjetos["aptoGabiJoao"]} depoimento={depoimentos["aptoGabiJoao"]} name={"aptoGabiJoao"}/>}/>
            <Route path={"/portfolio/aptoDaiane"} element={<ProjetoOneImgNoDepo click={setPage} colors={colorVariants[page]} info={dadosProjetos["aptoDaiane"]} name={"aptoDaiane"}/>}/>
            <Route path={"/portfolio/aptoVictoria"} element={<ProjetoTwoImagesDepo click={setPage} colors={colorVariants[page]} info={dadosProjetos["aptoVictoria"]} depoimento={depoimentos["aptoVictoria"]} name={"aptoVictoria"}/>}/>
            <Route path={"/portfolio/aptoCynthia"} element={<ProjetoOneImgNoDepo click={setPage} colors={colorVariants[page]} info={dadosProjetos["aptoCynthia"]} name={"aptoCynthia"}/>}/>
            <Route path={"/portfolio/cozinhaSA"} element={<ProjetoTwoImagesDepo click={setPage} colors={colorVariants[page]} info={dadosProjetos["cozinhaSA"]} depoimento={depoimentos["cozinhaSA"]} name={"cozinhaSA"}/>}/>
            <Route path={"/portfolio/aptoLilianLeandro"} element={<ProjetoTwoImagesDepo click={setPage} colors={colorVariants[page]} info={dadosProjetos["aptoLilianLeandro"]} depoimento={depoimentos["aptoLilianLeandro"]} name={"aptoLilianLeandro"}/>}/>
            <Route path={"/portfolio/aptoNathalia"} element={<AptoNathalia click={setPage} colors={colorVariants[page]} info={dadosProjetos["aptoNathalia"]} depoimento={depoimentos["aptoNathalia"]} name={"aptoNathalia"}/>}/>
            <Route path={"/portfolio/quartoLiz"} element={<ProjetoOneImageDepo click={setPage} colors={colorVariants[page]} info={dadosProjetos["quartoLiz"]} depoimento={depoimentos["quartoLiz"]} name={"quartoLiz"}/>}/>
            <Route path={"/portfolio/quartoGiuliana"} element={<ProjetoTwoImagesDepo click={setPage} colors={colorVariants[page]} info={dadosProjetos["quartoGiuliana"]} depoimento={depoimentos["quartoGiuliana"]} name={"quartoGiuliana"}/>}/>
            <Route path={"/portfolio/casaTerrao"} element={<ProjetoOneImgNoDepo click={setPage} colors={colorVariants[page]} info={dadosProjetos["casaTerrao"]} name={"casaTerrao"}/>}/>
            <Route path={"/portfolio/aptoLucca"} element={<ProjetoOneImageDepo click={setPage} colors={colorVariants[page]} info={dadosProjetos["aptoLucca"]} depoimento={depoimentos["aptoLucca"]} name={"aptoLucca"}/>}/>
        </Routes>
    );
};

export default Router;