import React from "react";

const Forms = () => {
    let width = 680;
    if(window.innerWidth < width) width = window.innerWidth;
    return (
        <div className="sm:w-11/12 mx-auto">
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSdxIvd0Wxc9YATLPgomc1z5SGcUsZRx6Uk1dksYMfYVmY-bFg/viewform?embedded=true"
                width={width} height="3200" title={"forms"} frameBorder="0" marginHeight="0" marginWidth="0" className={"mx-auto"}>Loading…
            </iframe>
        </div>
    )
};

export default Forms;