import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';


const Navbar = ( { page, click, colors } ) => {
    let logo, toggleIcon, closeIcon;
    if(page === "home" || page === "sobre"){
        logo = '/resources/imgs/logo/Logo_Purple.svg';
        toggleIcon = '/resources/imgs/navbar/togglePurple.svg';
        closeIcon = '/resources/imgs/navbar/closePurple.svg';
    }
    if(page === "portfolio"){
        logo = '/resources/imgs/logo/Logo_Red.svg';
        toggleIcon = '/resources/imgs/navbar/toggleRed.svg';
        closeIcon = '/resources/imgs/navbar/closeRed.svg';
    }
    if(page === "servicos"){
        logo = '/resources/imgs/logo/Logo_Green.svg';
        toggleIcon = '/resources/imgs/navbar/toggleGreen.svg';
        closeIcon = '/resources/imgs/navbar/closeGreen.svg';
    }
    if(page === "contato"){
        logo = '/resources/imgs/logo/Logo_Orange.svg';
        toggleIcon = '/resources/imgs/navbar/toggleOrange.svg';
        closeIcon = '/resources/imgs/navbar/closeOrange.svg';
    }

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const clickTransition = (nextPage) => {
        setIsOpen(!isOpen);
        click(nextPage);
    }

    return (
        <nav className={`fixed top-0 left-0 w-full bg-white bg-opacity-60 backdrop-blur-md z-40`}>
            <div className={`min-w-screen mx-auto px-4 sm:px-6 border-y-2 ${colors["border"]} `}>
                <div className="flex flex-row justify-between flex-grow h-14 sm:h-20">
                    <div className="flex items-center">
                        <Link to="/" className="flex flex-row items-center px-5 sm:px-10 text-3xl" onClick={() => clickTransition("home")}>
                            <img className={"h-12 sm:h-16 px-1"} src={logo} alt="JR"/>
                            <div className={`hidden sm:block font-sans font-bold px-1 ${colors["text"]}`}>
                                JORDHANA
                            </div>
                            <div className={`hidden sm:block font-sans ${colors["text"]}`}>
                                RAPOSO
                            </div>
                        </Link>
                    </div>
                    <div className="flex md:items-center md:space-x-4 px-10 text-2xl">
                        <Link to="/portfolio" className={`hidden xl:block default-font  hover:underline px-8 2xl:px-10 text-nowrap ${colors["text"]}`} onClick={() => click("portfolio")}>Portfólio</Link>
                        <Link to="/servicos" className={`hidden xl:block default-font hover:underline px-8 2xl:px-10 text-nowrap ${colors["text"]}`} onClick={() => click("servicos")}>Serviços</Link>
                        <Link to="/sobre" className={`hidden xl:block default-font hover:underline px-8 2xl:px-10 text-nowrap ${colors["text"]}`} onClick={() => click("sobre")}>Sobre nós</Link>
                        <Link to="/contato" className={`hidden xl:block default-font hover:underline px-8 2xl:px-10 text-nowrap ${colors["text"]}`} onClick={() => click("contato")}>Contato</Link>
                        <div onClick={toggleDropdown} className='block xl:hidden cursor-pointer my-auto'>
                            <img className={"h-6 sm:h-8"} src={isOpen ? closeIcon : toggleIcon} alt="JR"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${isOpen ? "block" : "hidden"} xl:hidden text-2xl flex flex-col justify-between flex-grow  border-b-2 ${colors["border"]} `}>
                    <Link to="/portfolio" className={`default-font mx-6 xl:px-4 text-nowrap font-bold ${colors["text"]} border-b-2 py-3 ${colors["border"]} text-right`} onClick={() => clickTransition("portfolio")}>Portfólio</Link>
                    <Link to="/servicos" className={`default-font mx-6 xl:px-4 text-nowrap font-bold ${colors["text"]} py-3  border-b-2 ${colors["border"]} text-right`} onClick={() => clickTransition("servicos")}>Serviços</Link>
                    <Link to="/sobre" className={`default-font mx-6 xl:px-4 text-nowrap font-bold ${colors["text"]} py-3  border-b-2 ${colors["border"]} text-right`} onClick={() => clickTransition("sobre")}>Sobre nós</Link>
                    <Link to="/contato" className={`default-font mx-6 xl:px-4 text-nowrap font-bold ${colors["text"]} py-3 text-right`} onClick={() => clickTransition("contato")}>Contato</Link>
                    <div onClick={toggleDropdown} className='flex-grow'/>
            </div>
        </nav>
    );
};

export default Navbar;