import React from 'react';
import Footer from "../../components/Footer";
import {Link} from "react-router-dom";

const AptoNathalia = ( { click, colors, info, depoimento, name } ) => {
    return (
        <div className="flex flex-col min-h-screen w-screen">
            <div className="flex flex-col w-11/12 lg:w-4/5 mx-auto">
                <div id='flex-container' className={`items-center flex flex-row flex-wrap pt-48 md:pt-64`}>
                    <div className={`font-sans ${colors["text"]} text-6xl pr-2`}>
                        {info["beginName"]}
                    </div>
                    <div className={`font-sans font-bold ${colors["text"]} text-6xl`}>
                        {info["endName"]}
                    </div>
                </div>
                <div id='flex-container' className={`items-center flex flex-row flex-wrap py-5`}>
                    <div className={`font-sans ${colors["text"]} text-2xl pr-2 underline`}>
                        {info["consultoria"] ? info["location"] + " l " + info["year"]+ " l CONSULTORIA" : info["location"] + " l " + info["year"]}
                    </div>
                </div>
                <div id='flex-container' className={`items-center flex flex-row flex-wrap pt-5`}>
                    <div className={`font-sans italic ${colors["text"]} text-lg lg:text-xl pr-2 whitespace-pre-wrap text-justify`}>
                        {typeof info["description"] === "string" ? info["description"] : info["description"]["opener"]}
                    </div>
                </div>
                <div className="flex flex-col pt-32">
                    <div className={`font-sans ${colors["text"]} text-4xl md:text-5xl pr-2 underline`}>
                        QUARTO DA LIZ
                    </div>
                    <div className={`font-sans ${colors["text"]} text-lg lg:text-xl whitespace-pre-wrap text-justify pt-8`}>
                        {info["description"]["quartoLiz"]}
                    </div>
                </div>
                <div id='flex-container' className={`items-center flex flex-row flex-wrap py-16 mx-auto`}>
                    <img src={`${"/resources/imgs/portfolio/projetos/"+name+"/1.png"}`} alt="NOT FOUND"/>
                </div>
                <hr className={`${colors["border"]} border-1 my-5 w-1/5 mx-auto`}/>
                <div id='flex-container' className={`items-center flex flex-col flex-wrap py-5`}>
                    <div className={`font-sans ${colors["text"]} text-xl md:text-2xl pr-2`}>
                        ESCUTE O CLIENTE!
                    </div>
                    <div id='flex-container' className={`items-center flex flex-row flex-wrap mx-auto text-4xl lg:text-6xl`}>
                        <div id='flex-container' className={`items-center flex flex-row flex-wrap mx-auto text-4xl lg:text-6xl`}>
                            <div className={`font-sans ${colors["text"]} pr-2`}>
                                DEPOIMENTO
                            </div>
                            <div className={`font-sans font-bold ${colors["text"]}`}>
                                {info["depoimentoName"]}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`text-center mx-auto my-5 ${colors["border"]} md:border-2 rounded-semifull py-5 md:px-20 ${colors["text"]} italic text-lg lg:text-xl whitespace-pre-wrap w-full xl:w-3/5`}>
                    {depoimento}
                </div>
                <hr className={`${colors["border"]} border-1 my-5 w-1/5 mx-auto`}/>
                <div className="flex flex-col pt-32">
                    <div className={`font-sans ${colors["text"]} text-4xl md:text-5xl pr-2 underline`}>
                        QUARTO DO THEO
                    </div>
                    <div className={`font-sans ${colors["text"]} text-lg lg:text-xl whitespace-pre-wrap text-justify pt-8`}>
                        {info["description"]["quartoTheo"]}
                    </div>
                </div>
                <div id='flex-container' className={`items-center flex flex-row flex-wrap py-16 mx-auto`}>
                    <img src={`${"/resources/imgs/portfolio/projetos/"+name+"/2.png"}`} alt="NOT FOUND"/>
                </div>
            </div>
            <div className="flex flex-col mx-auto pb-10 justify-center pt-8 md:pt-32 md:flex-row lg:flex-row-reverse">
                <div className="flex flex-col flex-grow items-center justify-center px-2 lg:px-10 pb-20 xl:pb-0 w-11/12 mx-auto my-auto">
                    <div className={`font-sans underline text-4xl sm:text-5xl xl:text-6xl ${colors["text"]} my-8 mx-auto`}>Não se acanhe!</div>
                    <Link to="/contato" onClick={() => click("contato")} className={`border ${colors["border"]} border-2 rounded-full mx-auto`}>
                        <p className={`font-sans ${colors["text"]} text-md sm:text-lg lg:text-xl text-center p-1.5`}> Preencha nosso formulário de orçamento</p>
                    </Link>
                </div>
                <div className={"mx-auto md:ml-auto w-3/5"}>
                    <img src="/resources/imgs/portfolio/banner.png" alt="BANNER"/>
                </div>
            </div>
            <Footer colors={colors}/>
        </div>
    );
};

export default AptoNathalia;